<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(38, 51, 82, 1), rgba(38, 51, 82, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    src="@/assets/img/lateralCover.png"
    mobile-break-point="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, .2)`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-row>
      <v-col>
        <v-list dense nav>
          <v-list-item>
            <img
              :src="imageUrlLogo"
              style="
                max-width: 220px;
                height: 100%;
                max-height: 40px;
                margin: auto;
              "
            />
          </v-list-item>
        </v-list>
        <v-divider class="mb-2" />

        <v-row class="mx-3 mt-2">
          <v-col>
            <v-avatar color="actionButton" size="36">
              <span class="white--text text-h5">{{
                this.user?.username && this.user.username[0]
              }}</span>
            </v-avatar>
            <span class="ml-2 white--text subtitle-2">{{
              this.user?.username
            }}</span>
          </v-col>
        </v-row>
        <v-list>
          <v-list-item v-if="modeHomolg">
            <p style="margin: auto" class="red--text">Homologação</p>
          </v-list-item>
        </v-list>

        <v-list expand nav>
          <template v-for="(item, a) in drawerItems">
            <base-item :key="`publicItem-${a}`" :item="item" />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    imageUrlLogo:
      "https://firebasestorage.googleapis.com/v0/b/corsolar-energy.appspot.com/o/corsolar%2Fcorsolar_logo_branco.png?alt=media&token=9263be35-a828-4158-9558-e949e82ce69f",
  }),
  created() {},

  mounted() {
    this.$forceUpdate();
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    modeHomolg() {
      return window.path == "/testes";
    },
    modeAdmin() {
      return this.user?.role?.id == 6;
    },

    userRoleId() {
      return this.user?.role?.id;
    },

    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    images: function () {
      var images = this.$session.get("images");
      if (images) return images;
      else
        return {
          logo: "https://firebasestorage.googleapis.com/v0/b/plataformacordeiro.appspot.com/o/corsolar%2Fcorsolar_logo_branco.png?alt=media&token=b1584c3f-7780-4bf1-9796-640ec482ed55",
          logoWhite:
            "https://firebasestorage.googleapis.com/v0/b/plataformacordeiro.appspot.com/o/corsolar%2Fcorsolar_logo_branco.png?alt=media&token=b1584c3f-7780-4bf1-9796-640ec482ed55",
        };
    },

    drawerItems: function () {
      var items = [];
      items.push(
        {
          icon: "mdi-solar-panel",
          title: "Minhas usinas",
          to: "/stations",
        },
        {
          icon: "mdi-bell-alert-outline",
          title: "Alarmes",
          to: "/alarmes",
        },
        {
          icon: "mdi-account",
          title: "Meus dados",
          to: "/account",
        }
      );

      if (
        this.user?.role?.name == "Admin" ||
        this.user?.role?.name == "Engineer"
      ) {
        items.push({
          icon: "mdi-account-group",
          title: "Integradores",
          to: "/integrators",
        });
      }

      if (this.user?.role?.name == "Integrator") {
        items.push({
          icon: "mdi-account-group",
          title: "Dados integrador",
          to: `/integrator/${this.user?.integrator?.id}`,
        });
      }

      return items;
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
